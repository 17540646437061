import React from "react";
import iconListaChamado from '../_assets/img/iconListaChamado.svg';

const IconListaChamado= () => {
    return (
        <>
            <img src={iconListaChamado} />
        </>

    );
}

export default IconListaChamado;