import React from "react";
import logoHeader from '../_assets/img/logoHeader.svg';

const LogoHeader= () => {
    return (
        <>
            <img src={logoHeader} />
        </>

    );
}

export default LogoHeader;