import React from "react";
import iconPhone from '../_assets/img/iconPhone.svg';

const IconPhone= () => {
    return (
        <>
            <img src={iconPhone} />
        </>

    );
}

export default IconPhone;