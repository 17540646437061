import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import IconVoltar from "../../components/iconVoltar";
import IconLoading from "../../components/iconLoading"
import OficinaAccordion from "./oficina";
import { getOficinasPorCep } from "../../service/Reparo";
import { GeralContext } from "../../context/GeralContext";

interface PropsSelectOficina {
    fncClosed: () => any,
    fncSelecioda: (oficina: any) => any
}

export const SelectOficina = (props: PropsSelectOficina) => {
    const navigate = useNavigate();
    const { userName, userCpfCpnj, token } = useContext(GeralContext);
    const [ver, setVer] = useState(false);
    const [cep, setCep] = useState("");
    const [oficinas, setOficinas] = useState<any[]>([]);
    const [load, setLoad] = useState("none");
    const [loadInfo, setLoadInfo] = useState("block");
    const [possuiDados, setPossuiDados] = useState(false);

    const toLink = (link: string) => {
        navigate(link);
    };    

    const filtrarOficinas = async (event: any) => {           
        event.preventDefault();
        
        setLoad('flex');
        setLoadInfo('none');
        setPossuiDados(false);

        const retorno = await getOficinasPorCep({ cep: cep, token: token });
        if(retorno.isError){
            console.log(retorno.message);
            return;
        }

        setOficinas(retorno.oficinas);

        setPossuiDados(true);
        setLoad('none');
        setLoadInfo('block');
        
    }

    return (
        <section className="bg-gray">
            <section className="content-pagina">
                <section className="bloco p-middle m-vertical">
                    <div className="titulo-bloco m-bottom" onClick={() => props.fncClosed()}><IconVoltar /> Cancelar</div>
                </section>
                <section className="bloco p-middle m-vertical">

                    <form className="form-login">
                        <label>Informe o CEP</label>
                        <div className="campo-input">
                            <input className="p-vertical" type="text" value={cep} onChange={(event: any) => { setCep(event.target.value) }}></input>
                            <button className="btn btn-yellow" style={{marginTop: "0.25px", marginBottom: "0.25px"}} onClick={filtrarOficinas}>Pesquisar</button>
                        </div>
                    </form>

                    <section className="bloco p-middle m-vertical" style={{display: load}}>
                        <div style= {{ display: load, width: '100%', alignItems:'center', justifyContent:'center', flexDirection:'column'}}><IconLoading/><h5>Aguarde! Carregando dados...</h5></div>
                    </section>

                    {
                        possuiDados &&
                        <h2 className="titulo-bloco m-bottom" style={{display: loadInfo}}>Selecione abaixo a oficina desejada: (Sujeito a 2º diagnostico)</h2>
                    }

                    <section className="bloco-100 flex-column" style={{display: loadInfo}}>
                        {
                            oficinas.map((oficina: any) => {
                                return(
                                    <section className="select-oficina m-bottom flex-row gap-1">
                                        <OficinaAccordion oficina={oficina} />
                                        <button onClick={() => {props.fncSelecioda(oficina)}}> + Selecionar</button>
                                    </section>
                                );
                            })
                        }
                    </section >

                </section >
            </section >
        </section >

    );
};
