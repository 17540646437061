import React from "react";
import iconWhatsSuporte from '../_assets/img/iconWhatsSuporte.svg';

const IconWhatsSuporte= () => {
    return (
        <>
            <img src={iconWhatsSuporte} />
        </>

    );
}

export default IconWhatsSuporte;