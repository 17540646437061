import React from "react";
import iconListaGarantia from '../_assets/img/iconListaGarantia.svg';

const IconListaGarantia= () => {
    return (
        <>
            <img src={iconListaGarantia} />
        </>

    );
}

export default IconListaGarantia;