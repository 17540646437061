import axios from "axios";
import React from "react";
import apiConst from "../api/apiConst";
import GarantiasInterface from "../interfaces/GarantiasInterface";
import DocumentoGarantiaInterface from "../interfaces/DocumentoGarantiaInterface";

const PegarGarantiaService = async (dados: DocumentoGarantiaInterface) => {
    const response = await axios.get("/api/v1/certificado-garantia/" + dados.id + "/true" ,  { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }})

    return { isError: false, link: response.data.base64 }
}

const ListaGarantiasService = async (dados: GarantiasInterface) => {
    const response = await axios.get("/api/v1/ativacao/segurado/all/documento/" + dados.cpfcnpj, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!response || !response.data){
        return { isError: true, message: "Erro ao consultar garantias!" }
    }

    return { isError: false, message: "Sucesso ao consultar garantias", garantias: response.data.data.data };    
}

export { 
    PegarGarantiaService,
    ListaGarantiasService
}