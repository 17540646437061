import axios from "axios";
import React from "react";
import apiConst from "../api/apiConst";
import UltimoChamadoInterface from "../interfaces/UltimoChamadoInterface";
import ChamadosInterface from "../interfaces/ChamadosInterface";
import ChamadoInterface from "../interfaces/ChamadoInterface";
import UploadFileInterface from "../interfaces/UploadFileInterface";

const UltimoChamadoService = async (dados: UltimoChamadoInterface) => {    
    const response = await axios.get("/api/v1/ativacao/segurado/documento/" + dados.cpfcnpj, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});
    
    if(!response || !response.data){
        return { isError: true, message: "Erro ao consultar segurado!" }
    }

    const seguradoId = response.data.dados.data.seg_id;

    const responseAtivacao = await axios.get("/api/v1/ativacao/segurado/all/" + seguradoId, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseAtivacao || !responseAtivacao.data){
        return { isError: true, message: "Erro ao consultar ativações!" }
    }

    const placa = responseAtivacao.data.data.data[0].ati_placa;

    const responseReparos = await axios.get("/api/v1/reparo/existe-reparo/" + placa, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseReparos || !responseReparos.data){
        return { isError: true, message: "Erro ao consultar reparos!" }
    }

    const ultimoReparo = responseReparos.data.data[responseReparos.data.data.length - 1].repa_id;

    const responseReparoDetalhes = await axios.get("/api/v1/reparo/detalhes/" + ultimoReparo, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseReparoDetalhes || !responseReparoDetalhes.data){
        return { isError: true, message: "Erro ao consultar detalhes do reparo!" }
    }

    const sinistro = responseReparoDetalhes.data.dados;
    const oficinaId = responseReparoDetalhes.data.dados.repa_ofi_id;

    const responseHistorico = await axios.get("/api/v1/reparoHistorico/detalhes/" + ultimoReparo, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseHistorico || !responseHistorico.data){
        return { isError: true, message: "Erro ao consultar histórico!" }
    }

    const historico = responseHistorico.data.data;

    const responseOficina = await axios.get("/api/v1/oficina/" + oficinaId + "/detalhes", { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseOficina || !responseOficina.data){
        return { isError: true, message: "Erro ao consultar detalhes da oficina!" }
    }

    const oficina = responseOficina.data.data;

    return { isError: false, message: "Sucesso ao consultar Sinistro", sinistro: sinistro, historico: historico, oficina: oficina }
}

const PegarChamadoService = async(dados: ChamadoInterface) => {

    const responseReparoDetalhes = await axios.get("/api/v1/reparo/detalhes/" + dados.id, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseReparoDetalhes || !responseReparoDetalhes.data){
        return { isError: true, message: "Erro ao consultar detalhes do reparo!" }
    }

    const sinistro = responseReparoDetalhes.data.dados;
    const oficinaId = responseReparoDetalhes.data.dados.repa_ofi_id;

    const responseHistorico = await axios.get("/api/v1/reparoHistorico/detalhes/" + dados.id, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseHistorico || !responseHistorico.data){
        return { isError: true, message: "Erro ao consultar histórico!" }
    }

    const historico = responseHistorico.data.data;

    const responseOficina = await axios.get("/api/v1/oficina/" + oficinaId + "/detalhes", { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseOficina || !responseOficina.data){
        return { isError: true, message: "Erro ao consultar detalhes da oficina!" }
    }

    const oficina = responseOficina.data.data;

    return { isError: false, message: "Sucesso ao consultar Sinistro", sinistro: sinistro, historico: historico, oficina: oficina }
}

const ListaChamadosService = async (dados: ChamadosInterface) => {
    const response = await axios.get("/api/v1/ativacao/segurado/documento/" + dados.cpfcnpj, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});
    
    if(!response || !response.data){
        return { isError: true, message: "Erro ao consultar segurado!" }
    }

    const seguradoId = response.data.dados.data.seg_id;

    const responseAtivacao = await axios.get("/api/v1/ativacao/segurado/all/" + seguradoId, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseAtivacao || !responseAtivacao.data){
        return { isError: true, message: "Erro ao consultar ativações!" }
    }

    const placa = responseAtivacao.data.data.data[0].ati_placa;

    const responseReparos = await axios.get("/api/v1/reparo/existe-reparo/" + placa, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseReparos || !responseReparos.data){
        return { isError: true, message: "Erro ao consultar reparos!" }
    }

    const sinistros = responseReparos.data.data;

    return { isError: false, message: "Sucesso ao buscar chamados", sinistros: sinistros }
}

const UploadFileService = async (dados: UploadFileInterface) => {    
    const response = await axios.post("/api/v1/reparo/" + dados.id + "/upload_files", dados.data, { baseURL: apiConst.urlServer, headers: {
        'Authorization': 'Bearer ' + dados.token,
        "Content-Type": "multipart/form-data"
    }});

    if(!response.data.status) return { isError: true, message: "Erro ao enviar documentos" };
    return {isError: false, message: "Sucesso ao enviar documentos" }
}

export { 
    UltimoChamadoService,
    PegarChamadoService,
    ListaChamadosService,
    UploadFileService
}