import axios from "axios";
import React from "react";
import apiConst from "../api/apiConst";
import AtivacoesConsultaInterface from "../interfaces/AtivacoesConsultaInterface";
import OficinaConsultaInterface from "../interfaces/OficinaConsultaInterface";
import ReparoCadastroInterface from "../interfaces/ReparoCadastroInterface";
import AtivacaoConsultaInterface from "../interfaces/AtivacaoConsultaInterface";

const getAtivacoes = async (dados: AtivacoesConsultaInterface) => {
    const response = await axios.get("/api/v1/ativacao/segurado/documento/" + dados.cpfcnpj, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});
    
    if(!response || !response.data){
        return { isError: true, message: "Erro ao consultar segurado!" }
    }

    const segurado = response.data.dados.data;

    const responseGarantias = await axios.get("/api/v1/ativacao/segurado/all/documento/" + dados.cpfcnpj, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!responseGarantias || !responseGarantias.data){
        return { isError: true, message: "Erro ao consultar garantias!" }
    }

    const ativacoes = responseGarantias.data.data.data;
    
    return { isError: false, message: "Sucesso ao consultar Sinistro", ativacoes: ativacoes, segurado: segurado }    
}

const getOficinasPorCep = async (dados: OficinaConsultaInterface) => {
    const response = await axios.post("/api/v1/paraCoordenadas", { "cep": dados.cep } , { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!response || !response.data){
        return { isError: true, message: "Erro ao coordenadas do cep!" }
    }

    const distancia = 10
    const latitude = response.data.lat;
    const longitude = response.data.lng;

    const responseOficinas = await axios.post("/api/v1/search-oficina/coordenadas", {
        "distancia": distancia, 
        "lat": latitude, 
        "lng": longitude }
        , { baseURL: apiConst.urlServer, headers: {
            'Authorization': 'Bearer ' + dados.token,
            'Accept': 'application/json'
        }});
    
     if(!responseOficinas || !responseOficinas.data){
        return { isError: true, message: "Oficinas não encontradas!" }
     }     

     const oficinas = responseOficinas.data.data;

     return { isError: false, message: "Sucesso ao consultar Oficinas", oficinas: oficinas }    
}

const gerarReparo = async (dados: ReparoCadastroInterface) => {
    const response = await axios.post("/api/v1/reparo/cadastrar", { 
        'repa_cli': dados.estipulante,
        'repa_km': dados.km,
        'repa_adm': dados.adm,
        'repa_obs': dados.obs,
        'repa_relato_consumidor': dados.relato,
        'repa_ofi_id': dados.oficinaId,
        'repa_veiculo': dados.veiculo,
        'repa_placa': dados.placa,
        'repa_prop_nome': dados.nome,
        'repa_prop_fones': "{\"fones\": [\"" + dados.telefone + "\"], \"cels\": null}",
        'repa_prop_email': dados.email,
        'repa_man': dados.manual,
        'repa_man_nro': dados.manualNumero,
        'repa_pro': dados.produto,
        'repa_ati': dados.ativacao,
        'repa_justificativa': dados.justificativa,
        'repa_detalhes': dados.detalhes,
        'repa_gerado_consumidor': 1
    } , { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!response || !response.data){
        return { isError: true, message: "Erro ao cadastrar chamado!" }
    }

    return { isError: false, message: "Sucesso ao cadastrar chamado" }    
}

const getAtivacao = async (dados: AtivacaoConsultaInterface) => {
    const response = await axios.get("/api/v1/apolice-emitida/detalhes/" + dados.id, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!response || !response.data){
        return { isError: true, message: "Erro ao consultar apolice!" }
    }

    const ativacao = response.data.data.dados;

    return { isError: false, message: "Sucesso ao consultar Oficinas", ativacao: ativacao }      
}

export {
    getAtivacoes,
    getOficinasPorCep,
    gerarReparo,
    getAtivacao
}