import React from "react";
import iconChatBot from '../_assets/img/iconChatBot.svg';

const IconChatBot= () => {
    return (
        <>
            <img src={iconChatBot} />
        </>

    );
}

export default IconChatBot;