import React from "react";
import iconSuporte from '../_assets/img/iconMenuSuporte.svg';

const iconMenuSuporte= () => {
    return (
        <>
            <img src={iconSuporte} />
        </>

    );
}

export default iconMenuSuporte;