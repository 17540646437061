import React from "react";
import iconVoltar from '../_assets/img/iconVoltar.svg';

const IconVoltar= () => {
    return (
        <>
            <img className="logo-login" src={iconVoltar} />
        </>

    );
}

export default IconVoltar;