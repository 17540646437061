import { useNavigate } from "react-router-dom"
import Home from "../pages/home/home"
import IconMenuBlog from "./iconMenuBlog"
import IconMenuConfig from "./iconMenuConfig"
import IconMenuHome from "./iconMenuHome"
import IconMenuSuporte from "./iconMenuSuporte"
import IconVoltar from "./iconVoltar"


const Menu = () => {
    const navigate = useNavigate();

    const toLink = (link: string) => {
        navigate(link);
    } 

    return (
        <section className="menu">
            <nav>
                <ul className="flex-menu p-top p-bottom">
                    <li><button onClick={() => { toLink('/home') }} className="btn-flex p-small"><IconMenuHome />Início</button></li>
                    <li><button onClick={() => { toLink('/suporte') }} className="btn-flex p-small"><IconMenuSuporte />Contato</button></li>
                    <li><button onClick={() => { window.location.href = "https://gestautobrasil.com.br" }} className="btn-flex p-small"><IconMenuBlog />Blog</button></li>
                    <li><button onClick={() => { toLink('/login') }} className="btn-flex p-small"><IconVoltar />Sair</button></li>      
                </ul>
            </nav>
        </section>
    )
}
export default Menu