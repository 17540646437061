import React from "react";
import iconePasswordView from '../_assets/img/iconPasswordView.svg';

const IconPasswordView = () => {
    return (
        <>
            <img className="logo-login" src={iconePasswordView} />
        </>

    );
}

export default IconPasswordView;