import React from "react";
import iconBlog from '../_assets/img/iconMenuBlog.svg';

const iconMenuBlog= () => {
    return (
        <>
            <img src={iconBlog} />
        </>

    );
}

export default iconMenuBlog;