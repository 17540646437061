import React from "react";
import iconUpload from '../_assets/img/iconUpload.svg';

const IconUpload= () => {
    return (
        <>
            <img src={iconUpload} />
        </>

    );
}

export default IconUpload;