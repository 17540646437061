import axios from "axios";
import React from "react";
import apiConst from "../api/apiConst";
import GarantiaInterface from "../interfaces/GarantiaInterface";
import GarantiaDetalhesInterface from "../interfaces/GarantiaDetalhesInterface";
import DocumentoPorEmailInterface from "../interfaces/DocumentoPorEmailInterface";

const PegarDadosGarantiaService = async (dados: GarantiaInterface) => {

    const response = await axios.get("/api/v1/emissao/detalhes/" + dados.id, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    if(!response){
        return { isError: true, message: "Garantia não encontrada!"}
    }
    
    return { isError: false, data: response.data.data.EMISSAO, segurado: response.data.data.SEGURADO}
}

const PegarDetalhesGarantiaService = async (dados: GarantiaDetalhesInterface) => {
    if(dados.operacao === "SEGURO JNS"){

    }
    else{
        const response = await axios.get("/api/v1/tabelas/detalhes/" + dados.tabela, { baseURL: apiConst.urlServer, headers : {
            'Authorization': 'Bearer ' + dados.token,
            'Accept': 'application/json'
        }});
        
        if(response.data.lstCertificados){
            const responseCertificado = await axios.get("/api/v1/docs/detalhes/" + response.data.lstCertificados[0].id, { baseURL: apiConst.urlServer, headers : {
                'Authorization': 'Bearer ' + dados.token,
                'Accept': 'application/json'
            }});
            
            return { isError: false, data: responseCertificado.data.data}
        }
        
    }

    return {isError: false, data: null }
}

const EnviarCertificadoEmail = async (dados: DocumentoPorEmailInterface) => {

    const response = await axios.post("/api/v1/ativacao/mailCertificado", { ati_id: dados.id, email: dados.email }, { baseURL: apiConst.urlServer, headers : {
        'Authorization': 'Bearer ' + dados.token,
        'Accept': 'application/json'
    }});

    console.log(response)
}

export {
    PegarDadosGarantiaService,
    PegarDetalhesGarantiaService,
    EnviarCertificadoEmail
}
