import { useContext, useEffect, useState } from "react"
import CardListaCertificado from "../../components/cardListaCertificado"
import HeaderApp from "../../components/header"
import IconListaGarantia from "../../components/iconListaGarantia"
import IconVoltar from "../../components/iconVoltar"
import IconLoading from "../../components/iconLoading"
import Menu from "../../components/menu"
import { GeralContext } from "../../context/GeralContext"
import { ListaGarantiasService } from "../../service/Garantias"
import moment from "moment"
import { Link } from "react-router-dom"

const ListaCertificados = () => {
    const [garantias, setGarantias] = useState<any[]>([]);
    const { userName, userCpfCpnj, token } = useContext(GeralContext);
    const [load, setLoad] = useState("flex");


    const loadDados = async (cpf: string) => {
        const retorno = await ListaGarantiasService({ cpfcnpj: userCpfCpnj, token: token });
        
        if (retorno) {
           setLoad("none")
        }
        
        if (retorno.isError) {
            console.log(retorno.message);
        }

        let garantiasNew: any[] = [];
        retorno.garantias.map((garantia: any) => {
            garantiasNew.push(garantia);
        });
        setGarantias(garantiasNew);
    }

    useEffect(() => {
        loadDados(userCpfCpnj)
    }, [userCpfCpnj]);

    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />
                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        <Link className="titulo-bloco m-bottom" to={"/certificado"}><IconVoltar />Meus Certificados</Link>
                        <p className="m-bottom">{userName}, aqui você poderá visualizar detalhes de seus certificados e solicitar segunda via.</p>
                        <div style= {{ display: load, width: '100%', alignItems:'center', justifyContent:'center', flexDirection:'column'}}><IconLoading/><h5>Aguarde! Carregando dados...</h5></div>
                    </section>
                    

                    <>
                        {
                            garantias.map((garantia: any) => {
                                return (
                                    <CardListaCertificado
                                        imagem={<IconListaGarantia />}
                                        data={['Data', moment(garantia.ati_data).format('DD/MM/YYYY')]}
                                        veiculo={['Veículo', garantia.ati_veiculo]}
                                        info={['Placa', garantia.ati_placa]}
                                        id={garantia.ati_id}
                                    />
                                )
                            })
                        }
                    </>
                </section>
                <Menu />
            </section>
        </section>
    )
}
export default ListaCertificados