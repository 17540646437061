import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import CardFullInterno from "../../components/cardFullInterno"
import CardMiddle from "../../components/cardMiddle"
import HeaderApp from "../../components/header"
import IconAgendamento from "../../components/iconAgendamento"
import IconAgendar from "../../components/iconAgendar"
import IconVoltar from "../../components/iconVoltar"
import IconWhatsApp from "../../components/iconWhatsApp"
import Menu from "../../components/menu"


const Revisao = () => {
    const navigate = useNavigate();

    const toLink = (link: string) => {
        navigate(link);
    } 
    
    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />

                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        <Link className="titulo-bloco m-bottom" to={"/home"}><IconVoltar /> Proxima revisão</Link>
                        <p className="m-bottom">Felipe, aqui você poderá agendar, atualizar e acompanhar sua próxima revisão.</p>
                        <section className="bloco-50-flex">
                            <CardMiddle
                                linkpage="/#"
                                imagem={<IconAgendamento />}
                                desc="Visualizar agendamento"
                            />
                            <CardMiddle
                                linkpage="/#"
                                imagem={<IconAgendar />}
                                desc="Agendar"
                            />
                        </section>
                    </section>
                    <CardFullInterno
                        nomebloco="Receber lembrete por Whatsapp?"
                        subtitulo="Seja notificado sobre suas próximas revisões, confirmações de agendamentos, e mais. Sem dor de cabeça."
                        imagem={<IconWhatsApp />}
                    />
                </section>
                <Menu/>
            </section>
        </section>
    )
}
export default Revisao