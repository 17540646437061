import { Link } from "react-router-dom"
import { useState } from "react"
import { SelectOficina } from "./SelectOficina"

interface PropsModal {
    isOpen: boolean    
    fncClosed: () => any
    fncSelecionada: (oficina: any) => any
}

const Modal = (props: PropsModal) => {    
    if (props.isOpen){
        return (
            <section className="modal">
                <SelectOficina fncClosed={props.fncClosed} fncSelecioda={props.fncSelecionada} />
            </section>
        )
    }
    return(<></>)
}
export default Modal