import React from "react";
import iconSegVia from '../_assets/img/iconSegVia.svg';

const IconSegVia= () => {
    return (
        <>
            <img src={iconSegVia} />
        </>

    );
}

export default IconSegVia;