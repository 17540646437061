import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import HeaderApp from "../../components/header"
import IconVoltar from "../../components/iconVoltar"
import Menu from "../../components/menu"
import IconReparo from "../../components/iconReparo"
import iconExibeDados from '../../_assets/img/iconExibeDados.svg'
import IconLoading from "../../components/iconLoading"
import iconFechar from '../../_assets/img/IconFechar.svg'
import Modal from "./modal"
import { GeralContext } from "../../context/GeralContext"
import { gerarReparo, getAtivacao, getAtivacoes } from "../../service/Reparo"
import Alert from "../../components/alert"


const Reparo = () => {
    const { userId, userName, userCpfCpnj, token } = useContext(GeralContext);
    const navigate = useNavigate();
    const [ativacoes, setAtivacoes] = useState<any[]>([]);
    const [ativacaoSelecionada, setAtivacaoSelecionada] = useState<any>({});
    const [nomeSegurado, setNomeSegurado] = useState("");
    const [emailSegurado, setEmailSegurado] = useState("");    
    const [telefoneSegurado, setTelefoneSegurado] = useState("");
    const [quilometragem, setQuilometragem] = useState(0); 
    const [oficinaSelecionada, setOficinaSelecionada] = useState<any>({});
    const [relato, setRelato] = useState("");
    const [ver, setVer] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [exibeMessage, setExibeMessage] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [severity, setSeverity] = useState('');
    const [load, setLoad] = useState("flex");
    const [loadInfo, setLoadInfo] = useState("none");

    const loadDados = async (cpf: string) => {
        const retorno = await getAtivacoes({ cpfcnpj: userCpfCpnj, token: token });
        if(retorno.isError){
            console.log(retorno.message);
            return;
        }
        
        setAtivacoes(retorno.ativacoes);           
        setNomeSegurado(retorno.segurado.seg_nome);
        setEmailSegurado(retorno.segurado.seg_email);
        setTelefoneSegurado(retorno.segurado.seg_cels);

        setLoad('none');
        setLoadInfo('block');        
    }

    useEffect(() => {
        loadDados(userCpfCpnj)
    }, [userCpfCpnj]);

    const OnOficinaSelecionada = (oficina: any) => {
        setOpenModal(false);        
        setOficinaSelecionada(oficina);
    }

    const onAtivacaoSelecionada = (event: any) => {
        ativacoes?.map((ativacao: any) => {
            if(ativacao.ati_placa === event.target.value){
                setAtivacaoSelecionada(ativacao);
            }
        })        
    }

    const abrirReparo = async (event: any) => {
        event.preventDefault();
        
        if(Object.keys(ativacaoSelecionada).length === 0 ){
            setMensagem("Selecione a placa do veículo!");
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 5000);
            return;
        }

        if(emailSegurado === ""){
            setMensagem("Informe o E-mail!");
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 5000);
            return;
        }

        if(telefoneSegurado === ""){
            setMensagem("Informe o Telefone!");
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 5000);
            return;
        }

        if(quilometragem === 0){
            setMensagem("Informe o KM atual!");
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 5000);
            return;
        }

        if(relato === ""){
            setMensagem("Passe informações sobre o reparo!");
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 5000);
            return;
        }

        if(Object.keys(oficinaSelecionada).length === 0 ){
            setMensagem("Selecione a oficina!");
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 5000);
            return;
        }

        const retorno = await gerarReparo({
            estipulante: ativacaoSelecionada?.ati_cli,
            email: emailSegurado,
            km: quilometragem.toString(),
            adm: userId.toString(),
            ativacao: ativacaoSelecionada?.ati_id,
            detalhes: "",
            justificativa: null,
            manual: ativacaoSelecionada?.ati_man,
            manualNumero: ativacaoSelecionada?.man_nro,
            nome: nomeSegurado,
            obs: "",
            oficinaId: oficinaSelecionada?.ofi_id,
            placa: ativacaoSelecionada?.ati_placa,
            produto: ativacaoSelecionada?.ati_pro,
            relato: relato,
            telefone: telefoneSegurado,
            token: token,
            veiculo: ativacaoSelecionada?.ati_veiculo,            
        });

        if(retorno.isError){
            setMensagem("Erro ao incluir chamado, veja os dados e tente novamente");
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 1000);
            return;
        }

        setMensagem("Chamado incluído com sucesso!");
        setSeverity("");
        setExibeMessage(true);
        setTimeout(() => { setExibeMessage(false); navigate("/chamado") }, 1000);
    }

    function handleClick() {
        setVer(!ver)
    }    

    let verDados = "";
    ver ? verDados = "block" : verDados = "none"

    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />

                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        <Link className="titulo-bloco m-bottom" to={"/chamado"}><IconVoltar /> Inclusão de Reparo</Link>
                    </section>

                    <section className="bloco p-middle m-vertical" style={{display: load}}>
                        <div style= {{ display: load, width: '100%', alignItems:'center', justifyContent:'center', flexDirection:'column'}}><IconLoading/><h5>Aguarde! Carregando dados...</h5></div>
                    </section>

                    <section className="bloco p-middle m-vertical" style={{display: loadInfo}}>

                        <h2 className="titulo-bloco m-bottom">Preencha os campos solicitados</h2>

                        <section className="bloco-100">
                            <section className="texto-suporte">
                                <form className="form-login" onSubmit={abrirReparo}>
                                    <label>Placa</label>
                                    <div className="campo-input">
                                        <select name="placa" onChange={onAtivacaoSelecionada}>
                                            <option value={""}>Selecione a placa</option>
                                            {
                                                ativacoes.map((ativacao: any) => {
                                                    return (<option key={ativacao.ati_placa} value={ativacao.ati_placa}>{ativacao.ati_placa}</option>)
                                                })
                                            }
                                        </select>                                        
                                    </div>

                                    <label>E-mail</label>
                                    <div className="campo-input">
                                        <input className="p-vertical" type="email" value={emailSegurado} onChange={(event: any) => { setEmailSegurado(event.target.value) }}></input>
                                    </div>

                                    <label>Telefone</label>
                                    <div className="campo-input">
                                        <input className="p-vertical" type="text" value={telefoneSegurado} onChange={(event: any) => { setTelefoneSegurado(event.target.value) }}></input>
                                    </div>

                                    <label>Quilometragem atual do veículo</label>
                                    <div className="campo-input">
                                        <input className="p-vertical" type="number" value={quilometragem} onChange={(event: any) => { setQuilometragem(event.target.value) }}></input>
                                    </div>

                                    <label>Conte-nos sobre o problema com o veículo</label>
                                    <div className="campo-input">
                                        <textarea className="p-vertical" rows={3} value={relato} onChange={(event: any) => { setRelato(event.target.value) }}></textarea>
                                    </div>
                                    <section className="select-oficina m-bottom">
                                        <button type="button" onClick={ () => setOpenModal(true) }>☑ Selecionar Oficina</button>
                                        <Modal isOpen={openModal} fncClosed={() => setOpenModal(false)} fncSelecionada={OnOficinaSelecionada} />
                                        
                                            <div className="info-garantia">
                                                <div onClick={handleClick} className="titulo-garantia">
                                                    <h2>Oficina selecionada:</h2>
                                                    <img src={ver ? iconFechar : iconExibeDados } />
                                                </div>
                                                <div style={{ display: verDados }} className="dados-garantia" >
                                                    <p><span className="nome-campo">Nome da Oficina:</span> {oficinaSelecionada?.ofi_razao} </p>
                                                    <p><span className="nome-campo">Endereço:</span> {oficinaSelecionada?.endereco}, {oficinaSelecionada?.numero}, {oficinaSelecionada?.bairro}, {oficinaSelecionada?.cidade}</p>
                                                    <p><span className="nome-campo">Distância:</span> {oficinaSelecionada?.distance} km</p>
                                                    <p><span className="nome-campo">Capacidade:</span> {oficinaSelecionada?.ofi_capacidade}</p>
                                                    <p><span className="nome-campo">Atendimento:</span> {oficinaSelecionada?.ofi_atendimento}</p>
                                                    <p><span className="nome-campo">Avaliação:</span> {oficinaSelecionada?.ofi_avaliacao}/5</p>
                                                    <p><span className="nome-campo">Serviços:</span> </p>
                                                    {
                                                    oficinaSelecionada?.servicos?.map((servico: any) => {
                                                        return(<p><span>- {servico.descricao}</span></p>)
                                                    })
                                                    }   
                                                </div>
                                            </div>
                                        
                                    </section>
                                    <section className="buttons">
                                        <button className="btn btn-yellow m-bottom">+ Incluir Reparo</button>
                                    </section>
                                    <Alert mensagem={mensagem} severity={severity} open={exibeMessage}/>
                                </form>
                            </section>

                        </section>
                    </section>
                </section>
                <Menu />
            </section>
        </section>
    )
}
export default Reparo