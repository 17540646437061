import { useNavigate } from "react-router-dom";

interface CardFullInternoProps{
    nomebloco: string;
    subtitulo: string;
    imagem: JSX.Element;
    linkpage?: string;
}
const CardFullInterno = (props: CardFullInternoProps) => {
    const navigate = useNavigate();

    const toLink = (link?: string) => {
        if(!link) return;
        navigate(link);
    } 

    return (
        <section className="bloco p-middle m-vertical"  onClick={() => { toLink( props.linkpage ) }}>
            <h2 className="titulo-bloco m-bottom">{props.nomebloco}</h2>
            <section className="bg-bloco bloco-100 p-larger">
                <section className="texto-bloco">
                    <p className="texto-80">{props.subtitulo}</p>                    
                </section>
                    {props.imagem}                      
            </section>
        </section>
    )
}
export default CardFullInterno

