import React from "react";
import iconNotificacao from '../_assets/img/iconAlertNotificacao.svg';

const IconAlertNotificacao= () => {
    return (
        <>
            <img src={iconNotificacao} />
        </>

    );
}

export default IconAlertNotificacao;