import React, { ChangeEvent, useState } from "react";
import './login.css';

import IconPassword from "../../components/iconPassword";
import LogoLogin from "../../components/logoLogin";
import IconVoltar from "../../components/iconVoltar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import loginService from "../../service/Login";
import Alert from "../../components/alert";
import RecuperaSenhaService from "../../service/RecuperarSenha";
import IconPasswordView from "../../components/iconPasswordView";


const RecuperarSenha = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [doc, setDoc] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaRepetida, setSenhaRepetida] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [severity, setSeverity] = useState('');
    const [exibeMessage, setExibeMessage] = useState(false);
    const [iconEye, setIconEye] = useState(IconPassword);
    const [iconEyeConfirm, setIconEyeConfirm] = useState(IconPassword);
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visiblePasswordConfirm, setVisiblePasswordConfirm] = useState(false);

    const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }
    const onChangeDoc = (event: ChangeEvent<HTMLInputElement>) => {
        setDoc(event.target.value)
    }
    const onChangeSenha = (event: ChangeEvent<HTMLInputElement>) => {
        setSenha(event.target.value)
    }
    const onChangeSenhaRepetida = (event: ChangeEvent<HTMLInputElement>) => {
        setSenhaRepetida(event.target.value)
    }

    const clickEye = (visible: boolean) => {        
        setVisiblePassword(!visible);        
        
        if(visible){
            setIconEye(IconPassword);
            return;
        }
        
        setIconEye(IconPasswordView)
    }

    const clickEyeConfirm = (visible: boolean) => {        
        setVisiblePasswordConfirm(!visible);        
        
        if(visible){
            setIconEyeConfirm(IconPassword);
            return;
        }
        
        setIconEyeConfirm(IconPasswordView)
    }

    const toLink = (link: string) => {
        navigate(link);
    } 

    const recuperarSenha = async () => {

        const respostaLogin = await loginService("suporte.ti@gestautobrasil.com.br", "suporte@ti");
        
        const respostaRecuperaSenha = await RecuperaSenhaService({
            email: email,
            password: senha,
            token: respostaLogin.data.access_token 
        });

        if(respostaRecuperaSenha.isError){            
            setMensagem(respostaRecuperaSenha.message);
            setSeverity('error');
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false)}, 5000);
            return;
        }
        if(!respostaRecuperaSenha.isError){
            setMensagem("Senha alterada com sucesso!");
            setSeverity("");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); navigate("/login") }, 4000);
        }
    }

    return (
        <section className="container-between">
            <section className="logo m-larger">
                <LogoLogin />
            </section>

            <section className="flex-column area-dados p-middle">
                <Link className="titulo-bloco m-bottom" to={"/"}><IconVoltar /></Link>
                <h2>Recuperar sua senha</h2>

                <section className="form">

                    <form className="form-login">
                        <label>E-mail</label>
                        <div className="campo-input">
                            <input className="p-vertical" type="email" value={email} onChange={onChangeEmail} ></input>
                        </div>
                        <label>CPF ou CNPJ</label>
                        <div className="campo-input">
                            <input className="p-vertical" type="doc" value={doc} onChange={onChangeDoc}></input>
                        </div>

                        <label>Senha</label>
                        <div className="campo-input">
                            <input className="p-vertical" type={visiblePassword ? "text" : "password"} value={senha} onChange={onChangeSenha}></input>
                            <button type="button" onClick={() => { clickEye(visiblePassword) } }> {iconEye} </button>
                        </div>
                        <label>Confirmar senha</label>
                        <div className="campo-input">
                            <input className="p-vertical" type={visiblePasswordConfirm ? "text" : "password"} value={senhaRepetida} onChange={onChangeSenhaRepetida}></input>
                            <button type="button" onClick={() => { clickEyeConfirm(visiblePasswordConfirm) } }> {iconEyeConfirm} </button>
                        </div>
                    </form>
                </section>
                <Alert mensagem={mensagem} severity={severity} open={exibeMessage}/>
                <section className="buttons">
                    <button onClick={recuperarSenha} className="btn btn-yellow m-bottom">Recuperar senha</button>
                </section>

                <section className="link-cadastro">
                    <button onClick={() => { toLink('/cadastro') }} className="btn-cadastro">Ainda não tem uma conta? <span className="link-bold">Cadastre-se aqui</span></button>
                </section>
            </section>

        </section >

    );
}

export default RecuperarSenha;