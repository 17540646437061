import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { GeralContextProvider } from './context/GeralContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GeralContextProvider>
      <App />
    </GeralContextProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
