import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import CardFullInterno from "../../components/cardFullInterno"
import CardMiddle from "../../components/cardMiddle"
import HeaderApp from "../../components/header"
import IconGarantia from "../../components/iconGarantia"
import IconResumo from "../../components/iconResumo"
import IconSegVia from "../../components/iconSegVia"
import IconVoltar from "../../components/iconVoltar"
import Menu from "../../components/menu"
import { useContext } from "react"
import { GeralContext } from "../../context/GeralContext"


const Certificado = () => {
    const navigate = useNavigate();
    const { userName } = useContext(GeralContext)

    const toLink = (link: string) => {
        navigate(link);
    } 
    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />

                <section className="content-pagina">

                    <section className="bloco p-middle m-vertical">
                        <Link className="titulo-bloco m-bottom" to={"/home"}><IconVoltar /> Certificado de garantia</Link>

                        <p className="m-bottom">{userName}, aqui você poderá verificar situações relacionadas ao seu certificado de garantia.</p>

                        <section className="bloco-50-flex">
                            <CardMiddle
                                linkpage="/lista-garantias"
                                imagem={<IconGarantia />}
                                desc="Verificar garantia"
                            />

                            <CardMiddle
                                linkpage="/lista-certificados"
                                imagem={<IconSegVia />}
                                desc="2ª via do certificado"
                            />

                        </section>

                    </section>

                    <CardFullInterno
                        nomebloco="Resumo do seu plano"
                        subtitulo="Veja a situação atual do seu plano. Seus benefícios, cobertura, e mais."
                        imagem={<IconResumo/>}
                    />

                </section>

                <Menu/>
            </section>
        </section>
    )
}
export default Certificado