import React from "react";
import iconAgendar from '../_assets/img/iconAgendar.svg';

const IconAgendar= () => {
    return (
        <>
            <img src={iconAgendar} />
        </>

    );
}

export default IconAgendar;