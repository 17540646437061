import React from "react";
import iconAlertSucess from '../_assets/img/iconAlertSucess.svg';

const IconAlertSucess= () => {
    return (
        <>
            <img src={iconAlertSucess} />
        </>
    );
}

export default IconAlertSucess;