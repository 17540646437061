import React from "react";

import IconNotificacao from "./iconNotification";
import IconPerfil from "./iconPerfil";
import LogoHeader from "./logoHeader";


const HeaderApp = () => {
    return(

    <header className="header-flex content-row m-middle">

        <LogoHeader />

        <section className="menu-perfil">
            <button className="p-middle"><IconNotificacao /></button>
            <button className="p-middle"><IconPerfil /></button>
        </section>

    </header>
);
}
export default HeaderApp;