import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import CardMiddle from "../../components/cardMiddle"
import HeaderApp from "../../components/header"
import IconChatBot from "../../components/iconChatBot"
import IconUltimosChamados from "../../components/iconUltimosChamados"
import IconLoading from "../../components/iconLoading"
import IconUpload from "../../components/iconUpload"
import IconVoltar from "../../components/iconVoltar"
import Menu from "../../components/menu"
import { GeralContext } from "../../context/GeralContext"
import { UltimoChamadoService, UploadFileService } from "../../service/Chamados"
import moment from "moment"
import {Helmet} from "react-helmet";

const ChatBoot = () => {
    const navigate = useNavigate();
    const { userName, userCpfCpnj, token } = useContext(GeralContext);
    const [chamado, setChamado] = useState<any>({});
    const [oficina, setOficina] = useState<any>({});
    const [historico, setHistorico] = useState<any[]>([]);
    const [load, setLoad] = useState("flex");
    const [loadInfo, setLoadInfo] = useState("none");
    let number = 0;    

    const toLink = (link: string) => {
        navigate(link);
    }

    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />
                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        <Link className="titulo-bloco m-bottom" to={"/chamado"}><IconVoltar /> Chat-Boot</Link>
                        <p className="m-bottom">{userName}, aqui você poderá entrar em contato com a gente!</p>                                                    
                    </section>
                    
                    <section className="bloco p-middle m-vertical">
                        <h2 className="titulo-bloco m-bottom">ChatBoot</h2>
                        <Helmet>
                            
                        </Helmet>
                        

                    </section>    
                </section>

                <Menu />
            </section>
        </section>
    )
}
export default ChatBoot