import IconError from "./iconAlertError";
import IconAlertNotificacao from "./iconAlertNotificacao";
import IconSucess from "./iconAlertSucess";

interface AlertProps {
    mensagem: string;
    severity: string;
    open: boolean;
}

const Alert = (props: AlertProps) => {

    const getAlertClass = () => {
        if(props.severity === 'error') return 'alert bg-alert-error bloco-100 p-small';
        if(props.severity === 'notify') return 'alert bg-alert-notificacao bloco-100 p-small';

        return 'alert bg-alert-success bloco-100 p-small';
    }

    const getAlertIcon = () => {
        if(props.severity === 'error') return(<IconError />) ;
        if(props.severity === 'notify')  return(<IconAlertNotificacao />);

        return(<IconSucess />);
    }

    const getDisplay = () => {
        if(props.open) return 'flex';
        
        return 'none';
    }

    return (
        <section className={getAlertClass()} style={{ display: getDisplay()}}>
            <div className="texto-alert">
                {getAlertIcon()}
                <h1>{props.mensagem}</h1>
            </div>
        </section>
    )
}
export default Alert

