import React from "react";
import iconResumo from '../_assets/img/iconResumo.svg';

const IconResumo= () => {
    return (
        <>
            <img src={iconResumo} />
        </>

    );
}

export default IconResumo;