import { useNavigate } from "react-router-dom"
import CardListaCertificado from "../../components/cardListaGarantia"
import HeaderApp from "../../components/header"
import IconListaChamado from "../../components/iconListaChamado"
import IconVoltar from "../../components/iconVoltar"
import IconLoading from "../../components/iconLoading"
import Menu from "../../components/menu"
import { useContext, useEffect, useState } from "react"
import { GeralContext } from "../../context/GeralContext"
import { ListaChamadosService } from "../../service/Chamados"
import moment from "moment"
import CardListaChamado from "../../components/cardListaChamado"

const ListaChamados = () => {
    const navigate = useNavigate();
    const [chamados, setChamados] = useState<any[]>([]);
    const { userName, userCpfCpnj, token } = useContext(GeralContext);
    const [load, setLoad] = useState("flex");

    const toLink = (link: string) => {
        navigate(link);
    } 

    const loadDados = async (cpf: string) => {
        const retorno = await ListaChamadosService({ cpfcnpj: userCpfCpnj, token: token });
        
        if (retorno) {
           setLoad("none")
        }
        
        if (retorno.isError) {
            console.log(retorno.message);
        }

        let chamadosNew: any[] = [];
        retorno.sinistros.map((chamado: any) => {
            chamadosNew.push(chamado);
        });
        setChamados(chamadosNew);
        console.log(chamadosNew);
    }

    useEffect(() => {
        loadDados(userCpfCpnj)
    }, [userCpfCpnj]);
    
    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />
                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        <h2 onClick={() => { toLink('/chamado') }} className="titulo-bloco m-bottom"><IconVoltar/>Meus Chamados</h2>
                        <p className="m-bottom">{userName}, aqui você poderá visualizar detalhes e histórico dos chamados.</p>
                        <div style= {{ display: load, width: '100%', alignItems:'center', justifyContent:'center', flexDirection:'column'}}><IconLoading/><h5>Aguarde! Carregando dados...</h5></div>
                    </section>
    
                    <>
                        {
                            chamados.map((chamado: any) => {
                                return (
                                    <CardListaChamado
                                        imagem={<IconListaChamado />}
                                        data={['Data', moment(chamado.repa_data_problema).format('DD/MM/YYYY')]}
                                        veiculo={['Veículo', chamado.repa_veiculo]}
                                        info={['Número Chamado', chamado.repa_id]}
                                        id={chamado.repa_id}
                                    />
                                )
                            })
                        }
                    </>
                </section>
                <Menu/>
            </section>
        </section>
    )
}
export default ListaChamados