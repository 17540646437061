import React from "react";
import iconPassword from '../_assets/img/iconPassword.svg';

const IconPassword= () => {
    return (
        <>
            <img className="logo-login" src={iconPassword} />
        </>

    );
}

export default IconPassword;