import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import HeaderApp from "../../components/header"
import Menu from "../../components/menu"


const Busca = () => {
    const navigate = useNavigate();

    const toLink = (link: string) => {
        navigate(link);
    } 
    
    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />

                <section className="content-pagina">
                <iframe src="https://gestautobrasil.com.br/"></iframe>
                </section>
                <Menu/>
            </section>
        </section>
    )
}
export default Busca