import { useNavigate } from "react-router-dom";

interface CardMiddleProps{
    linkpage: string;
    imagem:JSX.Element;
    desc: string;
}
const CardMiddle = (props: CardMiddleProps) => {
    const navigate = useNavigate();

    const toLink = (link: string) => {
        if(props.desc === 'chat-bot'){
            window.location.href = link;
        }
        navigate(link);
    } 
    
    return (
        <section onClick={() => { toLink( props.linkpage ) }} className="bg-bloco bloco-50 p-middle">
            {props.imagem}
            <div className="texto-bloco">
                <p className="texto-center">{props.desc}</p>
            </div>
        </section>
    );
}
export default CardMiddle

