import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import CardFullInterno from "../../components/cardFullInterno"
import CardMiddle from "../../components/cardMiddle"
import HeaderApp from "../../components/header"
import IconAgendamento from "../../components/iconAgendamento"
import IconAgendar from "../../components/iconAgendar"
import IconPhone from "../../components/iconPhone"
import IconVoltar from "../../components/iconVoltar"
import IconWhatsApp from "../../components/iconWhatsApp"
import IconWhatsSuporte from "../../components/iconWhatsSuporte"
import Menu from "../../components/menu"


const Suporte = () => {
    const navigate = useNavigate();

    const toLink = (link: string) => {
        navigate(link);
    }

    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />

                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        <Link className="titulo-bloco m-bottom" to={"/home"}><IconVoltar /> Contato</Link>
                    </section>
                    <section className="bloco p-middle m-vertical">
                        <h2 className="titulo-bloco m-bottom">Central de Assistência</h2>
                        <section className="bloco-100">
                            <section className="texto-suporte">
                                <p><b>Atenção:</b></p>
                                <p>Para solicitar reparos ou verificar os status do seu chamado, entre em contato através dos canais.</p>
                                <p><b><a href="tel:08007177776">0800 717 7776</a></b></p>
                                <p><IconPhone /><a href="tel:+554130463121"> (41) 3046 - 3121</a></p>
                                <p><IconWhatsSuporte /> <a href="https://api.whatsapp.com/send?phone=5541998524888">(41) 9 9852 - 4888</a></p>
                            </section>
                        </section>
                    </section>
                    <section className="bloco p-middle m-vertical">
                        <h2 className="titulo-bloco m-bottom">Onde estamos</h2>
                        <section className="bloco-100">
                            <section className="texto-suporte">
                                <p>Rua Emanuel Kant, 60 – Sala 1209, 12º andar</p>
                                <p>Edifício H.A Offices – Capão Raso – Curitiba – Paraná - CEP: 81.020-670</p>
                                <p><b> Horário de Atendimento:</b></p>
                                <p> De Segunda à Sexta-feira das 08h15 às 19h</p>
                                <p>-----</p>
                                <p><b>DPO – Central da Privacidade</b></p>
                                <p>Carla Taborda Ribas</p>
                                <p>dpo@tabordaribas.adv.br</p>
                            </section>
                        </section>
                    </section>
                    <section className="bloco p-middle m-vertical">

                        <h2 className="titulo-bloco m-bottom">Entre em contato conosco</h2>

                        <section className="bloco-100">
                            <section className="texto-suporte">
                                <form className="form-login">
                                    <label>Nome</label>
                                    <div className="campo-input">
                                        <input className="p-vertical" type="text"></input>
                                    </div>

                                    <label>E-mail</label>
                                    <div className="campo-input">
                                        <input className="p-vertical" type="email"></input>
                                    </div>

                                    <label>Telefone</label>
                                    <div className="campo-input">
                                        <input className="p-vertical" type="text"></input>
                                    </div>

                                    <label>Assunto</label>
                                    <div className="campo-input">
                                        <input className="p-vertical" type="text"></input>
                                    </div>

                                    <label>Mensagem</label>
                                    <div className="campo-input">
                                        <textarea className="p-vertical" rows={3}></textarea>
                                    </div>
                                </form>
                                <section className="buttons">
                                    <button className="btn btn-yellow m-bottom">Enviar</button>
                                </section>
                            </section>

                        </section>
                    </section>
                </section>
                <Menu />
            </section>
        </section>
    )
}
export default Suporte