import { useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import CardMiddle from "../../components/cardMiddle"
import HeaderApp from "../../components/header"
import IconChatBot from "../../components/iconChatBot"
import IconUltimosChamados from "../../components/iconUltimosChamados"
import IconLoading from "../../components/iconLoading"
import IconUpload from "../../components/iconUpload"
import IconVoltar from "../../components/iconVoltar"
import Menu from "../../components/menu"
import { GeralContext } from "../../context/GeralContext"
import { UltimoChamadoService, UploadFileService } from "../../service/Chamados"
import moment from "moment"
import Alert from "../../components/alert"
import CardFull from "../../components/cardFull"
import CardFullInterno from "../../components/cardFullInterno"
import IconReparo from "../../components/iconReparo"

const Chamado = () => {
    const navigate = useNavigate();
    const { userName, userCpfCpnj, token } = useContext(GeralContext);
    const [chamado, setChamado] = useState<any>({});
    const [oficina, setOficina] = useState<any>({});
    const [historico, setHistorico] = useState<any[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [load, setLoad] = useState("flex");
    const [loadInfo, setLoadInfo] = useState("none");
    const [mensagem, setMensagem] = useState('');
    const [severity, setSeverity] = useState('');
    const [exibeMessage, setExibeMessage] = useState(false);
    let number = 0;    

    const toLink = (link: string) => {
        navigate(link);
    }

    const loadDados = async (cpf: string) => {
        const retorno = await UltimoChamadoService({ cpfcnpj: userCpfCpnj, token: token });
        if(retorno.isError){
            console.log(retorno.message);
            return;
        }

        setOficina(retorno.oficina);
        setChamado(retorno.sinistro);
        setHistorico(getNovoHistorico(retorno.historico));
        setLoad('none');
        setLoadInfo('block');        
    }

    const getNovoHistorico = (historico: any[]) => {
        let count = -1;
        let dados : Array<any> = [];
        historico.map((hist: any) => {
          if(count >= 0){        
            if(hist.sta_nome != historico[count].sta_nome){
              dados.push(hist);
            }
          }
          else{
            dados.push(hist);
          }
          count++;
        });
    
        return dados;
    }

    useEffect(() => {
        loadDados(userCpfCpnj)
    }, [userCpfCpnj]);

    const uploadState = (event: any) => {
        setFiles(event.target.files);        
    }

    const handleUpload = async (event: any) => {                
        let formData = new FormData();        
        //const files: Array<File> = event.target.files;
        
        for (let i = 0; i < files.length; i++) {
            formData.append("file[]", files[i], files[i]['name']);
        }

        const retorno = await UploadFileService({ data: formData, token: token, id: chamado.repa_id });

        if(retorno.isError) {
            setMensagem(retorno.message);
            setSeverity("error");
            setExibeMessage(true);
            setTimeout(() => { setExibeMessage(false); }, 5000);                
        }

        setMensagem(retorno.message);
        setSeverity("");
        setExibeMessage(true);
        setTimeout(() => { setExibeMessage(false); }, 5000);
    }

    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />
                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        
                        <Link className="titulo-bloco m-bottom" to={"/home"}><IconVoltar /> Acompanhamento</Link>
                        <CardFullInterno 
                            imagem={<IconReparo />}
                            nomebloco=""
                            subtitulo="Abertura de Reparo"
                            linkpage="/reparo"
                        />

                        <section className="bloco-50-flex">
                            <CardMiddle
                                linkpage="https://api.whatsapp.com/send?phone=5541998524888"
                                imagem={<IconChatBot />}
                                desc="chat-bot"
                            />
                            {/* <CardMiddle
    linkpage="/lista-chamados"
    imagem={<IconUltimosChamados />}
    desc="Últimos chamados"
/> */}
                        </section>
                    </section>

                    <section className="bloco p-middle m-vertical" style={{display: load}}>
                        <div style= {{ display: load, width: '100%', alignItems:'center', justifyContent:'center', flexDirection:'column'}}><IconLoading/><h5>Aguarde! Carregando dados...</h5></div>
                    </section>
                    
                    <section className="bloco p-middle m-vertical" style={{display: loadInfo}}>
                        <h2 className="titulo-bloco m-bottom">Acompanhar reparo atual</h2>

                        <section className="bloco p-middle m-vertical">
                            <div className="dados-oficina">
                                <p>Obrigatorio agendamento com a oficina</p>
                            </div>
                            <div className="dados-oficina">
                                <p>Oficina:</p><h3>{oficina.ofi_fantasia}</h3>
                            </div>
                            <div className="dados-oficina">
                                <p>Telefones:</p><h3>{oficina.ofi_fones} | {oficina.ofi_cels}</h3>
                            </div>
                            <div className="dados-oficina">
                                <p>E-mail:</p><h3>{oficina.ofi_email}</h3>
                            </div>
                        </section>

                        <section className="acompanhamento m-vertical">
                            {
                                historico.map((hist: any) => {
                                    number++;
                                    return(<section className="passo m-bottom">
                                                <div className="data-passo"><p>{moment(hist.repa_data_criado).format('DD/MM/YYYY, HH:mm')}</p></div>
                                                <div className="numero-passo"><p>{number}</p></div>
                                                <div className="desc-passo">
                                                    <h3>{hist.sta_nome}</h3>                                                    
                                                </div>
                                            </section>)
                                })
                            }
                        </section>

                        <section className="upload m-vertical bg-gray p-small">
                            <IconUpload />
                            <h3 className="m-bottom">Para andamento do chamado: Envie os documentos solicitados abaixo:</h3>
                            <h3 className="m-bottom">DUT/CRVL, nota fiscal ou contrato de compra</h3>
                            <h3 className="m-bottom">Documento de identidade ou CNH do proprietário</h3>
                            <h3 className="m-bottom">Notas/cupons fiscais de manutenções</h3>
                            <h3 className="m-bottom">Foto do painel do veículo (indicador de quilometragem)</h3>
                            <h3 className="m-bottom">Certificado de Ativação da Garantia ASSINADO</h3>
                            <input type="file" multiple placeholder="Enviar arquivos" onChange={uploadState} />
                            <button onClick={handleUpload}>Enviar arquivos</button>
                        </section>

                        <Alert mensagem={mensagem} severity={severity} open={exibeMessage}/>
                    </section>
                </section>

                <Menu />
            </section>
        </section>
    )
}
export default Chamado