import axios from "axios";
import React from "react"
import apiConst from "../api/apiConst";

const loginService = async (user: string, password: string) => {

    return await axios.post("/oauth/token", {
        grant_type: "password",
        client_id: apiConst.clientId,
        client_secret: apiConst.clientSecret,
        username: user,
        password: password
    }, { baseURL: apiConst.urlServer});

}

export default loginService;