import React from "react";
import iconError from '../_assets/img/iconAlertError.svg';

const IconError= () => {
    return (
        <>
            <img src={iconError} />
        </>

    );
}

export default IconError;