import React, { Fragment, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Busca from "./pages/busca/busca";
import Certificado from "./pages/certificado/certificado";
import Chamado from "./pages/chamado/chamado";
import ListaChamados from "./pages/listaChamados/listaChamados";
import ListaGarantias from "./pages/listaGarantias/listaGarantias";
import RecuperarSenha from "./pages/login/recuperarSenha";
import Revisao from "./pages/revisao/revisao";
import Suporte from "./pages/suporte/suporte";
import ExibeGarantia from "./pages/exibeGarantia/exibeGarantia";
import ExibeChamado from "./pages/exibeChamado/exibeChamado";
import ListaCertificados from "./pages/listaCertificados/listaCertificados";
import ExibeCertificado from "./pages/exibeCertificado/exibeCertificado";
import ChatBoot from "./pages/chatBoot/chatBoot";
import Reparo from "./pages/reparo/reparo";
import { SelectOficina } from "./pages/reparo/SelectOficina";

const Login = lazy(() => import("./pages/login/login"));
const BemVindo = lazy(() => import("./pages/login/home"));
const Cadastro = lazy(() => import("./pages/login/cadastro"));
const Home = lazy(() => import("./pages/home/home"));

const Router: React.FC = () => {
    return(
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Fragment>
                    <Routes>  
                        <Route path="/" element={<BemVindo/>}/>          
                        <Route path="/login" element={<Login/>}/>             
                        <Route path="/cadastro" element={<Cadastro/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/recuperar-senha" element={<RecuperarSenha/>}/>
                        <Route path="/revisao" element={<Revisao/>}/>
                        <Route path="/certificado" element={<Certificado/>}/>
                        <Route path="/lista-garantias" element={<ListaGarantias/>}/>
                        <Route path="/exibe-garantia" element={<ExibeGarantia/>}/>
                        <Route path="/lista-chamados" element={<ListaChamados/>}/>
                        <Route path="/exibe-chamado" element={<ExibeChamado/>}/>
                        <Route path="/lista-certificados" element={<ListaCertificados/>}/>
                        <Route path="/exibe-certificado" element={<ExibeCertificado/>}/>
                        <Route path="/chamado" element={<Chamado/>}/>
                        <Route path="/chat-boot" element={<ChatBoot/> } />
                        <Route path="/suporte" element={<Suporte/>}/>
                        <Route path="/busca" element={<Busca/>}/>
                        <Route path="/reparo" element={<Reparo/>}/>                        
                    </Routes>
                </Fragment>
            </Suspense>
        </BrowserRouter>
    );
}

export default Router;