import React from "react";
import iconReparo from '../_assets/img/iconReparo.svg';

const IconReparo= () => {
    return (
        <>
            <img src={iconReparo} />
        </>

    );
}

export default IconReparo;