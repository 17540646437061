import { useContext, useEffect, useState } from "react"
import HeaderApp from "../../components/header"
import IconVoltar from "../../components/iconVoltar"
import IconLoading from "../../components/iconLoading"
import IconMail from "../../components/iconMail"
import Menu from "../../components/menu"
import { GeralContext } from "../../context/GeralContext"
import { EnviarCertificadoEmail, PegarDadosGarantiaService, PegarDetalhesGarantiaService } from "../../service/Garantia"
import { Link, useLocation } from "react-router-dom"
import iconExibeDados from '../../_assets/img/iconExibeDados.svg'
import iconFechar from '../../_assets/img/IconFechar.svg'
import moment from "moment"
import numeral from "numeral"

const ExibeGarantia = () => {
    const location = useLocation();
    const [garantia, setGarantia] = useState<any>({});
    const [segurado, setSegurado] = useState<any>({});
    const [certificado, setCertificado] = useState<string>('')
    const { userName, token } = useContext(GeralContext);
    const [load, setLoad] = useState("flex");
    const [loadInfo, setLoadInfo] = useState("none");
    const [numPages, setNumPages] = useState(null);
    const [ver, setVer] = useState(false)
    const [verProp, setVerProp] = useState(true)
    const [verInfo, setVerInfo] = useState(true)
    const [verTermo, setVerTermo] = useState(true)
    const [verItens, setVerItens] = useState(true)
    const [verManut, setVerManut] = useState(true)    

    const loadDados = async (id: number) => {        
        const response = await PegarDadosGarantiaService({ id: id, token: token });        
        setGarantia(response.data.dados);
        setSegurado(response.segurado);
        console.log(response.segurado);
        const responseTabela = await PegarDetalhesGarantiaService({ operacao: response.data.dados.ati_operacao, tabela: response.data.dados.ati_tbl, token: token});
        setCertificado(responseTabela.data.cer_content);        

        if (response) setLoad("none"); setLoadInfo("block");        
    }

    const sendEmail = async () => {
        const response = await EnviarCertificadoEmail({ id: garantia.ati_id, email: 'marceloitaguacu@gmail.com', token: token })
    }

    useEffect(() => {
        loadDados(location.state.id)
    }, [location.state.id]);

    const onDocumentLoadSuccess = (numPages: any) => {
        setNumPages(numPages);
    }

    function handleClick() {
        setVer(!ver)
    }
    let verDados= "";
    ver ? verDados = "none" : verDados = "block"

    function handleClickProp() {
        setVerProp(!verProp)
    }
    let verDadosProp = "";
    verProp ? verDadosProp = "none" : verDadosProp = "block"

    function handleClickInfo() {
        setVerInfo(!verInfo)
    }
    let verDadosInfo = "";
    verInfo ? verDadosInfo = "none" : verDadosInfo = "block"

    function handleClickTermo() {
        setVerTermo(!verTermo)
    }
    let verDadosTermo = "";
    verTermo ? verDadosTermo = "none" : verDadosTermo = "block"

    function handleClickItens() {
        setVerItens(!verItens)
    }
    let verDadosItens = "";
    verItens ? verDadosItens = "none" : verDadosItens = "block"

    function handleClickManut() {
        setVerManut(!verManut)
    }
    let verDadosManut = "";
    verManut ? verDadosManut = "none" : verDadosManut = "block"

    return (
        <section className="container-between bg-gray">
            <section className="content-app">
                <HeaderApp />
                <section className="content-pagina">
                    <section className="bloco p-middle m-vertical">
                        <Link className="titulo-bloco m-bottom" to={"/lista-garantias"}><IconVoltar />Informações da garantia</Link>
                        <p className="m-bottom">{userName}, aqui você poderá visualizar detalhes de sua garantia.</p>
                        <div style={{ display: load, width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}><IconLoading /><h5>Aguarde! Carregando dados...</h5></div>
                    </section>
                    <section className="bloco p-middle m-vertical" style={{display: loadInfo}}>
                        <h1 className="titulo-carro">{garantia.marca} {garantia.modelo} {garantia.ati_veiculo}</h1>
                        <div className="info-garantia">
                            <div onClick={handleClick} className="titulo-garantia">
                                <h2>Informações do Veículo</h2>
                                <img src={ver ? iconExibeDados : iconFechar} />
                            </div>
                            <div style={{ display: verDados }} className="dados-garantia" >
                                <p><span className="nome-campo">Marca do Veículo:</span> {garantia.marca}</p>
                                <p><span className="nome-campo">Modelo de Veículo:</span> {garantia.modelo}</p>
                                <p><span className="nome-campo">Ano de Fabricação:</span> {garantia.ano}</p>
                                <p><span className="nome-campo">Ano Modelo:</span> {garantia.ano}</p>
                                <p><span className="nome-campo">Placa:</span> {garantia.placa}</p>
                                <p><span className="nome-campo">Chassi:</span> {garantia.chassi}</p>
                                <p><span className="nome-campo">Quilometragem Atual:</span> {garantia.kma} km</p>
                                <p><span className="nome-campo">Quilometragem Última Manutenção:</span> {garantia.kmm} km</p>
                                <p><span className="nome-campo">Data Última Manutenção:</span> {garantia.datam}</p>
                                <p><span className="nome-campo">Características do Veículo:</span> {garantia.ati_veiculo}</p>
                            </div>
                        </div>
                        <div className="info-garantia">
                            <div onClick={handleClickProp} className="titulo-garantia">
                                <h2>Dados do Proprietário</h2>
                                <img src={verProp ? iconExibeDados : iconFechar} />
                            </div>
                            <div style={{ display: verDadosProp }} className="dados-garantia">
                                <p><span className="nome-campo">Nome:</span> {segurado?.seg_nome}</p>
                                <p><span className="nome-campo">CPF/CNPJ:</span> {segurado?.seg_cpfcnpj}</p>
                                <p><span className="nome-campo">E-mail</span> {segurado?.seg_email}</p>
                                <p><span className="nome-campo">Celular</span> {segurado?.seg_cels}</p>
                                <p><span className="nome-campo">Telefone</span> {segurado?.seg_fones}</p>
                                <p><span className="nome-campo">CEP:</span> {segurado?.addresses?.cep}</p>
                                <p><span className="nome-campo">Endereço:</span> {segurado?.addresses?.endereco},{segurado?.addresses?.numero}, {segurado?.addresses?.complemento}</p>
                                <p><span className="nome-campo">Bairro:</span> {segurado?.addresses?.bairro}</p>
                                <p><span className="nome-campo">Cidade / UF:</span> {segurado?.addresses?.cidade} / {segurado?.addresses?.uf}</p>

                            </div>
                        </div>
                        <div className="info-garantia">
                            <div onClick={handleClickInfo} className="titulo-garantia">
                                <h2>Informações da garantia</h2>
                                <img src={verInfo ? iconExibeDados : iconFechar} />
                            </div>
                            <div style={{ display: verDadosInfo }} className="dados-garantia">
                                <p><span className="nome-campo">Plano:</span> {garantia.produto}</p>
                                <p><span className="nome-campo">Início da Garantia:</span> {moment(garantia.dataVigencia).format('DD/MM/YYYY')}</p>
                                <p><span className="nome-campo">Término da Garantia:</span> {moment(garantia.ati_dt_expiracao).format('DD/MM/YYYY')}</p>
                                <p><span className="nome-campo">LMR (LIMITE MÁXIMO DE REPARO):</span> R$ { numeral(garantia.vlr_lmi).format('R$0,0.00')}</p>
                                <p><span className="nome-campo">Assistência 24 horas:</span> {garantia.ati_24h}</p>
                            </div>
                        </div>
                        <div className="info-garantia">
                            <div onClick={handleClickTermo} className="titulo-garantia">
                                <h2>Termo de consentimento</h2>
                                <img src={verTermo ? iconExibeDados : iconFechar} />
                            </div>
                            <div style={{ display: verDadosTermo }} className="dados-garantia">
                                <p>Termo de consentimento para armazenamento e tratamento de dados pessoais em conformidade com a LGPD. O proprietário, na condição de titular dos
                                    dados pessoais, manifesta livre, informada e inequívoca concordância com o tratamento de seus dados pessoais pela Gestauto Brasil para finalidade
                                    específica, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD), tendo assegurado seu direito de acesso, retificação e
                                    eliminação dos mesmos, devendo, para tanto, solicitar à Gestauto Brasil.</p>
                            </div>
                        </div>
                        <div className="info-garantia">
                            <div onClick={handleClickItens} className="titulo-garantia">
                                <h2>Manual do plano</h2>
                                <img src={verItens ? iconExibeDados : iconFechar} />
                            </div>
                            <div style={{ display: verDadosItens }} className="dados-garantia" dangerouslySetInnerHTML={{ __html: certificado }}>
                                
                            </div>
                        </div>                        
                        <div className="info-garantia">
                            <div className="dados-garantia">
                                <p>É obrigação do Proprietário realizar uma revisão periódica quando alcançados a quilometragem ou o prazo especificados na cláusula 10 do
                                     "Manual de serviços do plano contratado”, o que ocorrer primeiro, contados a partir da data de entrega do veículo.</p>
                            </div>
                        </div>
                        {/*<button className="btn-enviar-garantia" onClick={sendEmail}><IconMail/> Enviar certificado para meu e-mail</button>*/}
                    </section>
                </section>
                <Menu />
            </section>
        </section>
    )
}
export default ExibeGarantia