import React from "react";
import iconLoad from '../_assets/img/loading.gif';

const IconLoading= () => {
    return (
        <>
            <img src={iconLoad} style={{width:'80px'}} />
        </>

    );
}

export default IconLoading;