import { createContext, ReactNode, useEffect, useState } from "react";
import GestautoConst from "../GestautoConst";

type GeralContextProps = {
    children: ReactNode
}

type GeralContextType = {    
    token: string,
    userId: number,
    userName: string, 
    userCpfCpnj: string;  
    saveUser: (userId: number, userName: string, userCpfCpnj: string,  token: string) => void,
}

const initialValues = {    
    token: '',
    userId: 0,
    userName: '',    
    userCpfCpnj: '',
    saveUser: () => { }    
}

export const GeralContext = createContext<GeralContextType>(initialValues);

export const GeralContextProvider = ({ children }: GeralContextProps) => {    
    const [userId, setUserId] = useState(initialValues.userId);
    const [userName, setUserName] = useState(initialValues.userName);
    const [token, setToken] = useState(initialValues.token);
    const [userCpfCpnj, setUserCpfCnpj] = useState(initialValues.userCpfCpnj);
    
    useEffect(() => {
        const StorageUserId = sessionStorage.getItem(GestautoConst.store.userId);
        const StorageUserName = sessionStorage.getItem(GestautoConst.store.userName);
        const StorageToken = sessionStorage.getItem(GestautoConst.store.token);
        const StorageUserCpfCnpj = sessionStorage.getItem(GestautoConst.store.userCpjCnpj);
        
        setUserId(parseInt(StorageUserId ? StorageUserId : ''));
        setUserName(StorageUserName ? StorageUserName : '');        
        setToken(StorageToken? StorageToken : '');        
        setUserCpfCnpj(StorageUserCpfCnpj ? StorageUserCpfCnpj : '');
    }, []);

    const saveUser = (userId: number, userName: string, userCpfCpnj: string, token: string) => {
        setUserId(userId);
        setUserName(userName);
        setToken(token);
        setUserCpfCnpj(userCpfCpnj);

        sessionStorage.setItem(GestautoConst.store.userId, userId.toString());
        sessionStorage.setItem(GestautoConst.store.userName, userName);
        sessionStorage.setItem(GestautoConst.store.token, token);
        sessionStorage.setItem(GestautoConst.store.userCpjCnpj, userCpfCpnj);
    }

    
    return (
        <GeralContext.Provider value={{ userId, userName, userCpfCpnj, token, saveUser }}>
            {children}
        </GeralContext.Provider>
    )
}
