import React from "react";
import iconGarantia from '../_assets/img/iconGarantia.svg';

const IconGarantia= () => {
    return (
        <>
            <img src={iconGarantia} />
        </>

    );
}

export default IconGarantia;