import React, { useState } from 'react';
import iconExibeDados from '../../_assets/img/iconExibeDados.svg';
import iconFechar from '../../_assets/img/IconFechar.svg';

interface PropsOficinaAcoordion {
  oficina: any
}

const OficinaAccordion = (props: PropsOficinaAcoordion) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="info-garantia">
        <div onClick={() => setIsActive(!isActive)} className="titulo-garantia">
            <h2>{props.oficina.ofi_razao} | {props.oficina.distance} km </h2>
            <img src={isActive ? iconFechar : iconExibeDados} />
        </div>
        {isActive && <div className="dados-garantia">
            <p><span className="nome-campo">Nome da Oficina:</span> {props.oficina.ofi_razao} </p>
            <p><span className="nome-campo">Endereço:</span> {props.oficina.endereco}, {props.oficina.numero}, {props.oficina.bairro}, {props.oficina.cidade}</p>
            <p><span className="nome-campo">Distância:</span> {props.oficina.distance} km</p>
            <p><span className="nome-campo">Capacidade:</span> {props.oficina.ofi_capacidade}</p>
            <p><span className="nome-campo">Atendimento:</span> {props.oficina.ofi_atendimento}</p>
            <p><span className="nome-campo">Avaliação:</span> {props.oficina.ofi_avaliacao}/5</p>
            <p><span className="nome-campo">Serviços:</span> </p>
            {
              props.oficina.servicos.map((servico: any) => {
                return(<p><span>- {servico.descricao}</span></p>)
              })
            }            
        </div>}
    </div >
  );
};

export default OficinaAccordion;