import React from "react";
import iconNotification from '../_assets/img/iconNotificacao.svg';

const IconNotification= () => {
    return (
        <>
            <img src={iconNotification} />
        </>
    );
}

export default IconNotification;