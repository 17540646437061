import React from "react";
import iconWhatsApp from '../_assets/img/iconWhatsApp.svg';

const IconWhatsApp= () => {
    return (
        <>
            <img src={iconWhatsApp} />
        </>

    );
}

export default IconWhatsApp;