import { useContext, useEffect, useState } from "react";
import { PegarGarantiaService } from "../service/Garantias";
import { GeralContext } from "../context/GeralContext";
import { useNavigate } from "react-router-dom";

interface CardListaGarantiaProps{
    imagem: JSX.Element;
    data: Array<string>;
    veiculo: Array<string>;
    info: Array<string>;
    id: number;
}

const CardListaGarantia = (props: CardListaGarantiaProps) => {
    const { token} = useContext(GeralContext)
    const [link, setLink] = useState("");
    const navigate = useNavigate();
           
    const onClickVisualizarGarantia = async () => {        
        navigate("/exibe-garantia", { state: { id: props.id } })
    }
        
    return (
        <section className="bloco p-middle m-vertical" onClick={onClickVisualizarGarantia}>
            <section className="bg-bloco bloco-100-lista p-larger">
                    {props.imagem}  
                <section className="desc-garantia">
                    <p>{props.data[0]}: {props.data[1]}</p>
                    <p>{props.veiculo[0]}: {props.veiculo[1]}</p>
                    <p>{props.info[0]}: {props.info[1]}</p>
                </section>  
            </section>
        </section>
    )
}
export default CardListaGarantia

