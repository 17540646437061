import React from "react";
import logoLogin from '../_assets/img/logoLogin.svg';

const LogoLogin= () => {
    return (
        <>
            <img className="logo-login" src={logoLogin} />
        </>

    );
}

export default LogoLogin;