import React from "react";
import iconHome from '../_assets/img/iconMenuHome.svg';

const iconMenuHome= () => {
    return (
        <>
            <img src={iconHome} />
        </>

    );
}

export default iconMenuHome;