import React from "react";
import iconAgendamento from '../_assets/img/iconAgendamento.svg';

const IconAgendamento= () => {
    return (
        <>
            <img src={iconAgendamento} />
        </>

    );
}

export default IconAgendamento;